import { Component, OnInit } from '@angular/core';
import { GetUserObject } from 'projects/cxr-crm/src/app/pages/_helpers/get-user-object';
import { PrintService } from 'src/app/_services';

@Component({
  selector: 'cxr-print-booking',
  templateUrl: './print-booking.component.html',
  styleUrls: ['./print-booking.component.scss']
})
export class PrintBookingComponent implements OnInit {

  constructor (private printService: PrintService) { }
  OwnerCompanyName = GetUserObject.GetOwnerName();
  OwnerDetails = GetUserObject.GetUserObject();
  ngOnInit(): void {
    console.log(this.printService.bookingDetails);
    this.bookingData = this.printService.bookingDetails;
  }
  bookingData;
  ngAfterViewInit() {
    this.printService.openPrintBooking();
  }
}
