<div class="row">
    <div id="invoice-print">
        <div class="header">
            <div class="">
                <img [src]="OwnerDetails.LogoPath" alt="" class="owner-logo" />
            </div>
            <div class="summary"><b>{{OwnerCompanyName}}</b></div>

        </div>
        <div class="main">
            <div class="invoice-box">
                <h3 class="margin-0">{{bookingData.BookingExtID}}</h3>
                <!-- <h4 class="margin-0 red">{{'is Transfer' | translate}}: {{isTransfer ? 'Yes' : 'No'}}</h4> -->
                <h4>
                    {{'Booking Date' | translate}} : <span>{{bookingData.BookingDate}}</span>
                </h4>


                <h3 class="bg-ash margin-0 m-t-10 m-b-10 text-center">
                    <b>{{'Boooking Details' | translate}}</b>
                </h3>
                <table>
                    <tr>
                        <td>{{'Customer ID' | translate}} : <strong>{{bookingData.CustomerExtID}}</strong></td>
                        <td>{{'Currency' | translate}} : <strong>{{bookingData.Currency}}</strong></td>
                        <td>{{'Cash Deposit Received' | translate}} : <strong>{{bookingData.AdvancePaid}}</strong></td>
                    </tr>
                    <tr>
                        <td>{{'Amount' | translate}} : <strong>{{bookingData.Amount}}</strong></td>
                        <td>{{'Rate' | translate}} : <strong>{{bookingData.Rate}}</strong></td>
                        <td>{{'Inverse Rate' | translate}} : <strong>{{bookingData.InverseRate}}</strong></td>
                    </tr>
                    <tr>
                        <td>{{'Expiry Date' | translate}} : <strong>{{bookingData.ValidTill}}</strong></td>
                        <td>{{'Notes' | translate}} : <strong>{{bookingData.Notes}}</strong></td>
                    </tr>
                </table>
            </div>
        </div>
        <!-- <footer>
            <br>
            <div class="w-100 text-secondary text-center">
                <small>{{'Powered by CXR Cloud' | translate}}</small>
                &nbsp;<img class="cxr-logo" [src]="cxrLogoPath">
            </div>
        </footer> -->
    </div>
</div>
